import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const EditProject = () => {

  useRequireAuth();
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const initialState = {
    id: "",
    name: "",
    type: "",
    status: "",
    manager: "",
    startDate: "",
    endDate: "",
    notes: "",
  };

  const [projectData, setProjectData] = useState(initialState);

  const { name, type, status, manager, startDate, endDate, notes } = projectData;

  useEffect(() => {
    const fetchProjectData = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");

        const response = await axios.get(
          `${server.apiUrl}/getProject?id=${id}`
        );

        if (response.data.success) {
          setProjectData({ ...response.data.project, id });
        } else {
          setAlertType("error");
          setAlertMessage("Project could not be retrieved.");
        }
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Project could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchProjectData();
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "status" && value === "Active") {
  //     const formattedDateOfBirth = dateOfBirth
  //       ? dateOfBirth
  //         .split("-")
  //         .reverse()
  //         .map((d) => d.substr(-2))
  //         .join("")
  //       : "";

  //     setMemberData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //       password: formattedDateOfBirth
  //     }));
  //   } else {
  //     setMemberData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, ...updatedProjectData } = projectData;
      const response = await axios.put(`${server.apiUrl}/editProject`, {
        id,
        projectData: updatedProjectData,
      });
      if (response.data.success) {
        navigate("/projects");
      } else {
        setAlertType("error");
        setAlertMessage("Project could not be updated.");
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage("Project could not be updated.");
    }
  };

  const handleCancel = () => {
    navigate("/projects");
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Edit Project
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Type
                        </label>
                        <select
                          className="form-select"
                          id="type"
                          name="type"
                          value={type}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Project Type
                          </option>
                          <option key="RationDrive" value="Ration Drive">
                            Ration Drive
                          </option>
                          <option key="FinancialAssistance" value="Financial Assistance">
                            Financial Assistance
                          </option>
                          <option key="Other" value="Other">
                            Other
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          value={status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Project Status
                          </option>
                          <option key="NotStarted" value="Not Started">
                            Not Started
                          </option>
                          <option key="InProgress" value="In Progress">
                            In Progress
                          </option>
                          <option key="Completed" value="Completed">
                            Completed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="manager" className="form-label">
                          Manager
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="manager"
                          name="manager"
                          value={manager}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          value={startDate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="endDate" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          value={endDate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleCancel}
                      >
                        <i className="bi bi-x-square me-2"></i>Back
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditProject;