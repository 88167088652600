import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import MaskedInput from "react-text-mask";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const EditMember = () => {

  useRequireAuth();
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const initialState = {
    id: "",
    name: "",
    email: "",
    password: "",
    contactNo: "",
    address: "",
    dateOfBirth: "",
    gender: "",
    education: "",
    occupation: "",
    joiningDate: "",
    role: "",
    pastExperience: "",
    coreCompetence: "",
    notes: "",
    status: ""
  };

  const [memberData, setMemberData] = useState(initialState);

  const {
    name,
    email,
    password,
    contactNo,
    address,
    dateOfBirth,
    gender,
    education,
    occupation,
    pastExperience,
    coreCompetence,
    notes,
    role,
    status
  } = memberData;

  useEffect(() => {
    const fetchMemberData = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");

        const response = await axios.get(
          `${server.apiUrl}/getMember?id=${id}`
        );

        if (response.data.success) {
          setMemberData({ ...response.data.member, id });
        } else {
          setAlertType("error");
          setAlertMessage("Member could not be retrieved.");
        }
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Member could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchMemberData();
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "status" && value === "Active") {
      const formattedDateOfBirth = dateOfBirth
        ? dateOfBirth
          .split("-")
          .reverse()
          .map((d) => d.substr(-2))
          .join("")
        : "";

      setMemberData((prevData) => ({
        ...prevData,
        [name]: value,
        password: formattedDateOfBirth
      }));
    } else {
      setMemberData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, ...updatedMemberData } = memberData;
      const response = await axios.put(`${server.apiUrl}/editMember`, {
        id,
        memberData: updatedMemberData,
      });
      if (response.data.success) {
        navigate("/members");
      } else {
        setAlertType("error");
        setAlertMessage("Member could not be updated.");
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage("Member could not be updated.");
    }
  };

  const handleCancel = () => {
    navigate("/members");
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Edit Member
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="contactNo" className="form-label">
                          Contact No.
                        </label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          className="form-control"
                          id="contactNo"
                          name="contactNo"
                          value={contactNo}
                          onChange={handleChange}
                          placeholder="0000-0000000"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="dateOfBirth" className="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          value={dateOfBirth}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          Gender
                        </label>
                        <select
                          className="form-select"
                          id="gender"
                          name="gender"
                          value={gender}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Gender
                          </option>
                          <option key="Male" value="Male">
                            Male
                          </option>
                          <option key="Female" value="Female">
                            Female
                          </option>
                          <option key="Other" value="Other">
                            Other
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="education" className="form-label">
                          Education
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="education"
                          name="education"
                          value={education}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="occupation" className="form-label">
                          Occupation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="occupation"
                          name="occupation"
                          value={occupation}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="coreCompetence" className="form-label">
                          Core Competence
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="coreCompetence"
                          name="coreCompetence"
                          value={coreCompetence}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="role" className="form-label">
                          Role
                        </label>
                        <select
                          className="form-select"
                          id="role"
                          name="role"
                          value={role}
                          onChange={handleChange}
                        >
                          <option value="">
                            Select Role
                          </option>
                          <option key="Admin" value="Admin">
                            Admin
                          </option>
                          <option key="Official" value="Official">
                            Official
                          </option>
                          <option key="Coordinator" value="Coordinator">
                            Coordinator
                          </option>
                          <option key="Patron" value="Patron">
                            Patron
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          value={status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Status
                          </option>
                          <option key="Active" value="Active">
                            Active
                          </option>
                          <option key="Inactive" value="Inactive">
                            Inactive
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          value={password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          value={address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="pastExperience" className="form-label">
                          Past Volunteering Experience
                        </label>
                        <textarea
                          className="form-control"
                          id="pastExperience"
                          name="pastExperience"
                          value={pastExperience}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleCancel}
                      >
                        <i className="bi bi-x-square me-2"></i>Cancel
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditMember;