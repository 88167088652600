import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import server from '../config/server';

const DeleteBeneficiary = ({ beneficiary, onDelete }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${server.apiUrl}/deleteBeneficiary?id=${beneficiary.formId}`
      );
      onDelete(beneficiary);
    } catch (error) {
      console.error(`Error removing beneficiary:`, error);
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-danger"
        onClick={() => setShowConfirmation(true)}
      >
        <i className="bi bi-trash3-fill"></i>
      </button>

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this beneficiary?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-success"
            onClick={() => setShowConfirmation(false)}
          >
            <i className="bi bi-x-square me-2"></i>Close
          </Button>
          <Button variant="outline-danger" onClick={handleDelete}>
            <i className="bi bi-trash3-fill me-2"></i>Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteBeneficiary;