import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import MaskedInput from "react-text-mask";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const EditBeneficiary = () => {

  useRequireAuth();
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const initialState = {
    createdBy: "",
    createdDate: "",
    id: "",
    name: "",
    cnic: "",
    monthlyIncome: 0,
    contactNo: "",
    address: "",
    city: "",
    occupation: "",
    education: "",
    incomeSource: "",
    residence: "",
    dependentsCount: 0,
    notes: "",
    isVerified: false,
    coordinator: ""
  };

  const [beneficiaryData, setBeneficiaryData] = useState(initialState);

  const { id, name, cnic, monthlyIncome, contactNo, address, city, occupation, education, incomeSource, residence, dependentsCount, notes, isVerified, coordinator, createdBy } = beneficiaryData;

  useEffect(() => {
    const fetchBeneficiaryData = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams(location.search);
        const formId = queryParams.get("id");

        const response = await axios.get(
          `${server.apiUrl}/getBeneficiary?id=${formId}`
        );

        if (response.data.success) {
          setBeneficiaryData({ ...response.data.beneficiary, formId });
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiary could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Beneficiary could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchBeneficiaryData();
  }, [location.search]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "status" && value === "Active") {
  //     const formattedDateOfBirth = dateOfBirth
  //       ? dateOfBirth
  //         .split("-")
  //         .reverse()
  //         .map((d) => d.substr(-2))
  //         .join("")
  //       : "";

  //     setBeneficiaryData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //       password: formattedDateOfBirth
  //     }));
  //   } else {
  //     setBeneficiaryData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBeneficiaryData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { formId, ...updatedBeneficiaryData } = beneficiaryData;
      const response = await axios.put(`${server.apiUrl}/editBeneficiary`, {
        id: formId,
        beneficiaryData: updatedBeneficiaryData,
      });
      if (response.data.success) {
        navigate("/beneficiaries");
      } else {
        setAlertType("danger");
        setAlertMessage("Beneficiary could not be updated.");
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage("Beneficiary could not be updated.");
    }
  };

  const handleCancel = () => {
    navigate("/beneficiaries");
  };

  return (
    <div>
      {userData && (userData.role === "Admin" || userData.role === "Coordinator") ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Edit Beneficiary
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="id" className="form-label">
                          ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="id"
                          name="id"
                          value={id}
                          onChange={handleChange}
                          required
                          readOnly={userData.role === "Coordinator"}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="contactNo" className="form-label">
                          Contact No.
                        </label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          className="form-control"
                          id="contactNo"
                          name="contactNo"
                          value={contactNo}
                          onChange={handleChange}
                          placeholder="0000-0000000"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="cnic" className="form-label">
                          CNIC
                        </label>
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]}
                          className="form-control"
                          id="cnic"
                          name="cnic"
                          value={cnic}
                          onChange={handleChange}
                          placeholder="00000-0000000-0"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="occupation" className="form-label">
                          Occupation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="occupation"
                          name="occupation"
                          value={occupation}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="education" className="form-label">
                          Education
                        </label>
                        <select
                          className="form-select"
                          id="education"
                          name="education"
                          value={education}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Education
                          </option>
                          <option key="Uneducated" value="Uneducated">
                            Uneducated
                          </option>
                          <option key="Primary" value="Primary">
                            Primary
                          </option>
                          <option key="Middle" value="Middle">
                            Middle
                          </option>
                          <option key="Matric" value="Matric">
                            Matric
                          </option>
                          <option key="Higher" value="Higher">
                            Higher
                          </option>
                          <option key="Other" value="Other">
                            Other
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="dependentsCount" className="form-label">
                          No. of Dependents
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="dependentsCount"
                          name="dependentsCount"
                          value={dependentsCount}
                          onChange={handleChange}
                          min="0"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="monthlyIncome" className="form-label">
                          Monthly Income
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="monthlyIncome"
                          name="monthlyIncome"
                          value={monthlyIncome}
                          onChange={handleChange}
                          min="0"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="incomeSource" className="form-label">
                          Income Source
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="incomeSource"
                          name="incomeSource"
                          value={incomeSource}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="residence" className="form-label">
                          Residence
                        </label>
                        <select
                          className="form-select"
                          id="residence"
                          name="residence"
                          value={residence}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Residence
                          </option>
                          <option key="owned" value="owned">
                            Owned
                          </option>
                          <option key="rented" value="rented">
                            Rented
                          </option>
                          <option key="other" value="other">
                            Other
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          value={address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {userData && userData.role === "Admin" &&
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="isVerified" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="isVerified"
                            name="isVerified"
                            value={isVerified}
                            onChange={handleChange}
                            required
                          >
                            <option value="">
                              Select Status
                            </option>
                            <option value={true}>
                              Verified
                            </option>
                            <option value={false}>
                              Unverified
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="coordinator" className="form-label">
                            Coordinator
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="coordinator"
                            name="coordinator"
                            value={coordinator}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="createdBy" className="form-label">
                            Created by
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="createdBy"
                            name="createdBy"
                            value={createdBy}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleCancel}
                      >
                        <i className="bi bi-x-square me-2"></i>Cancel
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditBeneficiary;