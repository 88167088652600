import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD-ALsTpJmxB_tk3FN1g8jPMl7mK-ptmNk",
    authDomain: "rearingyouth-a2539.firebaseapp.com",
    projectId: "rearingyouth-a2539",
    storageBucket: "rearingyouth-a2539.appspot.com",
    messagingSenderId: "290163851849",
    appId: "1:290163851849:web:7489b5672af0944f6c3598",
    measurementId: "G-N0K1F8D3ZQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getFirestore(app);
const storage = getStorage();


// Enable persistent authentication state
setPersistence(auth, browserLocalPersistence);

export { auth, database, storage, ref, uploadBytes, getDownloadURL };
export default app;