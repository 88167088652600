import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const ProjectBeneficiaries = ({ beneficiary }) => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    projectId: "",
    status: "Not Delivered",
    notes: "",
  };

  const [projectData, setProjectData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);

  const { projectId, notes } = projectData;

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  // useEffect(() => {
  //   const generateProjectId = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`${server.apiUrl}/getProjectCount`);
  //       const totalCount = response.data.projectCount;
  //       const newId = `P${String(totalCount + 1).padStart(5, '0')}`;
  //       setProjectData((prevData) => ({
  //         ...prevData,
  //         id: newId,
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching total count of projects:", error);
  //     }
  //     setIsLoading(false);
  //   };
  //   generateProjectId();
  // }, [formReset]);


  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getAllProjects`);

        if (response.data.success) {
          if (response.data.projects.length)
            setProjects(response.data.projects);
          else {
            setAlertType("warning");
            setAlertMessage("No projects found.");
          }
        } else {
          setAlertType("error");
          setAlertMessage("Projects could not be retrieved.");
        }
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Projects could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchProjects();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addProjectBeneficiary`, {
        projectData,
        beneficiaryId: beneficiary.id,
      });

      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setProjectData(initialState);
        setFormReset(!formReset);
      } else {
        setAlertType("danger");
        setAlertMessage(`Failed to add project: ${response.data.error}`);
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage(`Failed to add project: ${error.message}`);
    }
    setIsLoading(false);
  };

  return (
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Add Beneficiary to Project</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {userData && userData.role === "Admin" ? (
            <>
              <div className="container mt-1">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <form id="projectBeneficiaryForm" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="projectId" className="form-label">
                              Project
                            </label>
                            <select
                              className="form-select"
                              id="projectId"
                              name="projectId"
                              value={projectId}
                              onChange={handleChange}
                              required
                            >
                              <option value="">
                                Select Project
                              </option>
                              {projects.map(project => (
                                <option key={project.id} value={project.id}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="status" className="form-label">
                              Status
                            </label>
                            <select
                              className="form-select"
                              id="status"
                              name="status"
                              value={status}
                              onChange={handleChange}
                              required
                            >
                              <option value="">
                                Select Status
                              </option>
                              <option key="Delivered" value="Delivered">
                                Delivered
                              </option>
                              <option key="NotDelivered" value="Not Delivered">
                                Not Delivered
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="notes" className="form-label">
                              Notes
                            </label>
                            <textarea
                              className="form-control"
                              rows="4"
                              id="notes"
                              name="notes"
                              value={notes}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                        </div>
                      </div>
                    </form>
                    {alertMessage && <Alert type={alertType} message={alertMessage} />}
                  </>
                )
                }
              </div>
            </>
          ) : (
            <Error />
          )}
        </div>
        <div class="modal-footer">
          <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
            <i className="bi bi-x-square me-2"></i>Cancel
          </button>
          <button type="submit" form="projectBeneficiaryForm" className="btn btn-outline-success me-2">
            <i className="bi bi-save me-2"></i>Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectBeneficiaries;