import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import BeneficiariesReport from './BeneficiariesReport';
import ReportTwo from './ReportTwo';

const Reports = () => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    reportType: "",
    project: "",
    coordinator: "",
    startDate: "",
    endDate: "",
  };

  const [reportData, setReportData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);
  const [coordinators, setCoordinators] = useState([]);
  const [projects, setProjects] = useState([]);

  const { reportType, projectId, coordinatorId, startDate, endDate } = reportData;

  const navigate = useNavigate();

  // State variables to manage the alert
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [coordinatorsResponse, projectsResponse] = await Promise.all([
          axios.get(`${server.apiUrl}/getAllMembers`),
          axios.get(`${server.apiUrl}/getAllProjects`),
        ]);

        if (coordinatorsResponse.data.success) {
          setCoordinators(coordinatorsResponse.data.members);
        } else {
          setAlertType("danger");
          setAlertMessage("Coordinators could not be retrieved.");
        }

        if (projectsResponse.data.success) {
          setProjects(projectsResponse.data.projects);
        } else {
          setAlertType("danger");
          setAlertMessage("Projects could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage(`Failed to fetch data: ${error.message}`);
      }
      //setDateTime(new Date().toString());
      setIsLoading(false);
    };

    fetchData();
  }, [userData]);

  // useEffect(() => {
  //   const generateBeneficiaryId = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`${server.apiUrl}/getBeneficiaryCount`);
  //       const totalCount = response.data.beneficiaryCount;
  //       const newId = `B${String(totalCount + 1).padStart(5, '0')}`;
  //       setBeneficiaryData((prevData) => ({
  //         ...prevData,
  //         id: newId,
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching total count of beneficiaries:", error);
  //     }
  //     setIsLoading(false);
  //   };
  //   generateBeneficiaryId();
  // }, [formReset]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //setFormData(data); // Save form data
    // Determine which report component to render based on the form data
    if (reportData.reportType === "Coordinator Beneficiaries") {
      setSelectedReport('BeneficiariesReport');
    } else {
      setAlertType("warning");
      setAlertMessage(`Report not available!`);
      //setSelectedReport('ReportTwo');
    }
    // try {
    //   const response = await axios.post(`${server.apiUrl}/addBeneficiary`, {
    //     reportData,
    //     createdBy: userData.id
    //   });

    //   if (response.status === 201) {
    //     setAlertType("success");
    //     setAlertMessage(response.data.message);
    //     setReportData(initialState);
    //     setFormReset(!formReset);
    //   } else {
    //     setAlertType("danger");
    //     setAlertMessage(`Failed to add beneficiary: ${response.data.error}`);
    //   }
    // } catch (error) {
    //   setAlertType("danger");
    //   setAlertMessage(`Failed to add beneficiary: ${error.message}`);
    // }
    // navigate(`/reports/beneficiaries`);
    // setAlertType("primary");
    // setAlertMessage(`Work in progress!`);
  };

  // const handleBack = () => {
  //   navigate(`/beneficiaries`);
  // };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          {(isLoading || selectedReport === null) && <NavBar />}
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              selectedReport === 'BeneficiariesReport' ? (
                <BeneficiariesReport coordinatorId={reportData.coordinatorId} />
              ) : (
                selectedReport === 'ReportTwo' ? (
                  <ReportTwo />
                ) : (
                  <>
                    <h2>
                      Reports
                    </h2>
                    <form onSubmit={handleSubmit} className="mt-4">
                      <div className="row">
                        <div className="col-md-6 offset-md-3">
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 offset-md-3">
                          <div className="mb-3">
                            <label htmlFor="reportType" className="form-label me-5">
                              Report Type
                            </label>
                            {/* <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                      <label class="form-check-label" for="inlineRadio1">Beneficiaries by Project</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                      <label class="form-check-label" for="inlineRadio2">Beneficiaries by Coordinator</label>
                    </div> */}
                            <select
                              className="form-select"
                              id="reportType"
                              name="reportType"
                              value={reportType}
                              onChange={handleChange}
                              required
                            >
                              <option value="">
                                Select Report Type
                              </option>
                              <option key="CoordinatorBeneficiaries" value="Coordinator Beneficiaries">
                                Beneficiaries by Coordinator
                              </option>
                              <option key="ProjectBeneficiaries" value="Project Beneficiaries">
                                Beneficiaries by Project
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {reportType === 'Coordinator Beneficiaries' && (
                        <div className="row">
                          <div className="col-md-6 offset-md-3">
                            <div className="mb-3">
                              <label htmlFor="coordinatorId" className="form-label">
                                Coordinator
                              </label>
                              <select
                                className="form-select"
                                id="coordinatorId"
                                name="coordinatorId"
                                value={coordinatorId}
                                onChange={handleChange}
                                required
                              >
                                <option value="">
                                  Select Coordinator
                                </option>
                                {coordinators.map(coordinator => (
                                  <option key={coordinator.id} value={coordinator.id}>
                                    {coordinator.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {reportType === 'Project Beneficiaries' && (
                        <div className="row">
                          <div className="col-md-6 offset-md-3">
                            <div className="mb-3">
                              <label htmlFor="projectId" className="form-label">
                                Project
                              </label>
                              <select
                                className="form-select"
                                id="projectId"
                                name="projectId"
                                value={projectId}
                                onChange={handleChange}
                                required
                              >
                                <option value="">
                                  Select Project
                                </option>
                                {projects.map(project => (
                                  <option key={project.id} value={project.id}>
                                    {project.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                      Start Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      value={startDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="form-label">
                      End Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      name="endDate"
                      value={endDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div> */}
                      <div className="row mt-4">
                        <div className="col-md-6 offset-md-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-outline-success me-2"
                          >
                            <i className="bi bi-save me-2"></i>Generate Report
                          </button>
                          {/* <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={handleBack}
                  >
                    <i className="bi bi-x-square me-2"></i>Back
                  </button> */}
                        </div>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-md-6 offset-md-3">
                        {alertMessage && <Alert type={alertType} message={alertMessage} />}
                      </div>
                    </div>
                  </>
                )
              )
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default Reports;