import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
// import MaskedInput from "react-text-mask";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

import { storage, ref, uploadBytes, getDownloadURL } from '../config/firebaseConfig';


const AddDonation = () => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    createdDate: new Date().toISOString().split("T")[0],
    paymentDate: "",
    donorId: "",
    amount: 0,
    purpose: "",
    paymentMethod: "",
    paymentReceipt: "",
    notes: "",
    isVerified: false
  };

  const [donationData, setDonationData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);

  const { paymentDate, amount, purpose, paymentMethod, notes } = donationData;

  const navigate = useNavigate();

  // State variables to manage the alert
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDonationData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const generateDonationId = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getDonationCount`);
        const totalCount = response.data.donationCount;
        const newId = `D${String(totalCount + 1).padStart(5, '0')}`;
        setDonationData((prevData) => ({
          ...prevData,
          id: newId,
        }));
      } catch (error) {
        console.error("Error fetching total count of donations:", error);
      }
      setIsLoading(false);
    };
    generateDonationId();
  }, [formReset]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${server.apiUrl}/addDonation`, {
  //       donationData,
  //       donorId: userData.id
  //     });

  //     if (response.status === 201) {
  //       setAlertType("success");
  //       setAlertMessage(response.data.message);
  //       setDonationData(initialState);
  //       setFormReset(!formReset);
  //     } else {
  //       setAlertType("danger");
  //       setAlertMessage(`Failed to add donation: ${response.data.error}`);
  //     }
  //   } catch (error) {
  //     setAlertType("danger");
  //     setAlertMessage(`Failed to add donation: ${error.message}`);
  //   }
  // };

  const handleBack = () => {
    navigate(`/donations`);
  };

  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];

  //   const storageRef = ref(storage, `paymentReceipts/${file.name}`); // Create a reference to the file in storage
  //   try {
  //     await uploadBytes(storageRef, file); // Upload the file to Firebase Storage
  //     const fileURL = await getDownloadURL(storageRef); // Get the download URL of the uploaded file
  //     setDonationData((prevData) => ({
  //       ...prevData,
  //       paymentReceipt: fileURL // Store the download URL in the donation data
  //     }));
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const file = document.getElementById("paymentReceipt").files[0]; // Get the file from the file input

      let fileURL = ""; // Initialize fileURL variable

      if (file) {
        const storageRef = ref(storage, `donationReceipts/${file.name}`); // Create a reference to the file in storage
        await uploadBytes(storageRef, file); // Upload the file to Firebase Storage
        fileURL = await getDownloadURL(storageRef); // Get the download URL of the uploaded file
      }

      // Form submission and file storage will happen together
      const response = await axios.post(`${server.apiUrl}/addDonation`, {
        donationData: {
          ...donationData,
          paymentReceipt: fileURL // Include fileURL in donationData
        },
        donorId: userData.id
      });

      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setDonationData(initialState);
        setFormReset(!formReset);
      } else {
        setAlertType("danger");
        setAlertMessage(`Failed to add donation 1: ${response.data.error}`);
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage(`Failed to add donation 2: ${error.message}`);
    }
  };


  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h2>
              Add Donation
            </h2>
            <Alert type="warning" message="Kindly complete this form to register your donation following your payment. Once your payment is received, our treasurer will promptly update the status to verified." />
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="purpose" className="form-label">
                      Purpose<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="purpose"
                      name="purpose"
                      value={purpose}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Purpose
                      </option>
                      <option key="RationDrive" value="Ration Drive">
                        Ration Drive
                      </option>
                      <option key="FinancialAssistance" value="Financial Assistance">
                        Financial Assistance
                      </option>
                      <option key="GeneralContribution" value="General Contribution">
                        General Contribution
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="amount" className="form-label">
                      Amount (PKR)<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="amount"
                      value={amount}
                      onChange={handleChange}
                      min="1"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="paymentDate" className="form-label">
                      Payment Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="paymentDate"
                      name="paymentDate"
                      value={paymentDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="paymentMethod" className="form-label">
                      Payment Method<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="paymentMethod"
                      name="paymentMethod"
                      value={paymentMethod}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Payment Method
                      </option>
                      <option key="Bank(Summit)" value="Bank (Summit)">
                        Bank (Summit)
                      </option>
                      <option key="JazzCash" value="JazzCash">
                        JazzCash
                      </option>
                      <option key="EasyPaisa" value="EasyPaisa">
                        EasyPaisa
                      </option>
                      <option key="Cash" value="Cash">
                        Cash
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="paymentReceipt" className="form-label">
                      Payment Receipt
                      {/* {/* <span className="text-danger">*</span>  */}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="paymentReceipt"
                      name="paymentReceipt"
                    //value={paymentReceipt}
                    //onChange={handleFileUpload}
                    //required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="notes" className="form-label">
                      Notes
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      id="notes"
                      name="notes"
                      value={notes}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-outline-success me-2"
                  >
                    <i className="bi bi-save me-2"></i>Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={handleBack}
                  >
                    <i className="bi bi-x-square me-2"></i>Back
                  </button>
                </div>
              </div>
            </form>
            {alertMessage && <Alert type={alertType} message={alertMessage} />}
          </>
        )
        }
      </div >
    </div >
  );
};

export default AddDonation;