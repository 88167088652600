import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const EditDonation = () => {

  useRequireAuth();
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const initialState = {
    createdDate: "",
    paymentDate: "",
    donorId: "",
    amount: 0,
    purpose: "",
    paymentMethod: "",
    paymentReceipt: "",
    notes: "",
    isVerified: false
  };

  const [donationData, setDonationData] = useState(initialState);

  const { paymentDate, amount, purpose, paymentMethod, paymentReceipt, notes, isVerified } = donationData;

  useEffect(() => {
    const fetchDonation = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams(location.search);
        const formId = queryParams.get("id");

        const response = await axios.get(
          `${server.apiUrl}/getDonation?id=${formId}`
        );

        if (response.data.success) {
          setDonationData({ ...response.data.donation, formId });
        } else {
          setAlertType("danger");
          setAlertMessage("Donation could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Donation could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchDonation();
  }, [location.search]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "status" && value === "Active") {
  //     const formattedDateOfBirth = dateOfBirth
  //       ? dateOfBirth
  //         .split("-")
  //         .reverse()
  //         .map((d) => d.substr(-2))
  //         .join("")
  //       : "";

  //     setBeneficiaryData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //       password: formattedDateOfBirth
  //     }));
  //   } else {
  //     setBeneficiaryData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDonationData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { formId, ...updatedDonationData } = donationData;
      const response = await axios.put(`${server.apiUrl}/editDonation`, {
        id: formId,
        donationData: updatedDonationData,
      });
      if (response.data.success) {
        navigate("/donations");
      } else {
        setAlertType("danger");
        setAlertMessage("Donation could not be updated.");
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage("Donation could not be updated.");
    }
  };

  const handleCancel = () => {
    navigate("/donations");
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Edit Donation
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="purpose" className="form-label">
                          Purpose<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="purpose"
                          name="purpose"
                          value={purpose}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Purpose
                          </option>
                          <option key="RationDrive" value="Ration Drive">
                            Ration Drive
                          </option>
                          <option key="FinancialAssistance" value="Financial Assistance">
                            Financial Assistance
                          </option>
                          <option key="GeneralContribution" value="General Contribution">
                            General Contribution
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="amount" className="form-label">
                          Amount (PKR)<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="amount"
                          name="amount"
                          value={amount}
                          onChange={handleChange}
                          min="1"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="paymentDate" className="form-label">
                          Payment Date<span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="paymentDate"
                          name="paymentDate"
                          value={paymentDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="paymentMethod" className="form-label">
                          Payment Method<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="paymentMethod"
                          name="paymentMethod"
                          value={paymentMethod}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Payment Method
                          </option>
                          <option key="Bank(Summit)" value="Bank (Summit)">
                            Bank (Summit)
                          </option>
                          <option key="JazzCash" value="JazzCash">
                            JazzCash
                          </option>
                          <option key="EasyPaisa" value="EasyPaisa">
                            EasyPaisa
                          </option>
                          <option key="Cash" value="Cash">
                            Cash
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="isVerified" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="isVerified"
                          name="isVerified"
                          value={isVerified}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Status
                          </option>
                          <option value={true}>
                            Verified
                          </option>
                          <option value={false}>
                            Unverified
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="paymentReceipt" className="form-label">
                          Payment Receipt
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="paymentReceipt"
                          name="paymentReceipt"
                          value={paymentReceipt}
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleCancel}
                      >
                        <i className="bi bi-x-square me-2"></i>Cancel
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditDonation;