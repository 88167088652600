import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Spinner from "./Spinner";
import Alert from "./Alert";
//import Graph from "./Graph";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const Home = () => {
  useRequireAuth();
  const { userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const [beneficiarySummary, setBeneficiarySummary] = useState({});
  const [donationSummary, setDonationSummary] = useState({});
  const [totalDonations, setTotalDonations] = useState(0);
  const [totalBeneficiaries, setTotalBeneficiaries] = useState(0);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  // useEffect(() => {
  //   const fetchBeneficiaries = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.post(`${server.apiUrl}/getGroupedBeneficiaries`, {
  //         userId: userData.id,
  //         userRole: userData.role
  //       });

  //       if (response.data.success) {
  //         setBeneficiarySummary(response.data.beneficiarySummary);
  //       } else {
  //         setAlertType("danger");
  //         setAlertMessage("Beneficiaries could not be retrieved.");
  //       }
  //     } catch (error) {
  //       setAlertType("danger");
  //       setAlertMessage("Beneficiaries could not be retrieved.");
  //     }
  //     setDateTime(new Date().toString());
  //     setIsLoading(false);
  //   };

  //   fetchBeneficiaries();
  // }, [userData]);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [groupedBeneficiariesResponse, groupedDonationsResponse] = await Promise.all([
          axios.post(`${server.apiUrl}/getGroupedBeneficiaries`, {
            userId: userData.id,
            userRole: userData.role
          }),
          axios.post(`${server.apiUrl}/getGroupedDonations`, {
            userId: userData.id,
            userRole: userData.role
          }),
        ]);

        if (groupedBeneficiariesResponse.data.success) {
          setBeneficiarySummary(groupedBeneficiariesResponse.data.beneficiarySummary);
          setTotalBeneficiaries(Object.values(groupedBeneficiariesResponse.data.beneficiarySummary).reduce((acc, coordinator) => acc + parseFloat(coordinator.count), 0));
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiaries could not be retrieved.");
        }

        if (groupedDonationsResponse.data.success) {
          setDonationSummary(groupedDonationsResponse.data.donationSummary);
          setTotalDonations(Object.values(groupedDonationsResponse.data.donationSummary).reduce((acc, donor) => acc + parseFloat(donor.totalDonations), 0));
        } else {
          setAlertType("danger");
          setAlertMessage("Donations could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage(`Failed to fetch data: ${error.message}`);
      }
      setDateTime(new Date().toString());
      setIsLoading(false);
    };

    fetchData();
  }, [userData]);

  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {userData && <h2>Hello, {userData.name}!</h2>}
        {isLoading ? (
          <Spinner />
        ) : alertMessage ? (
          <Alert type={alertType} message={alertMessage} />
        ) : userData && userData.role === "Admin" ? (
          <div className="row">
            <div className="col-md-6">
              <div className="card text-center mt-5">
                <div className="card-body">
                  <h3 className="text-start p-1 mt-2 mb-2">Beneficiary Summary</h3>
                  <table className="table table-sm table-striped table-hover mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Coordinator ID</th>
                        <th scope="col">Coordinator Name</th>
                        <th scope="col">Beneficiary Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(beneficiarySummary).map(([id, member]) => (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>{member.name}</td>
                          <td>{member.count}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="2">Total Beneficiaries</th>
                        <th>{totalBeneficiaries.toLocaleString()}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="card-footer text-body-secondary">
                  <i>As of: {dateTime}</i>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center mt-5">
                <div className="card-body">
                  <h3 className="text-start p-1 mt-2 mb-2">Donation Summary</h3>
                  <table className="table table-sm table-striped table-hover mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Donor ID</th>
                        <th scope="col">Donor Name</th>
                        <th scope="col">Total Donations (PKR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(donationSummary).map(([id, donor]) => (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>{donor.name}</td>
                          <td>{parseFloat(donor.totalDonations).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="2">Total Donations</th>
                        <th>{totalDonations.toLocaleString()}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="card-footer text-body-secondary">
                  <i>As of: {dateTime}</i>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-3">
              {/* <div className="col-md-7 mt-5"> */}
              {/* <Graph graphData={budgetSummary} /> */}
              <div className="alert alert-warning mt-5" role="alert">
                Your Beneficiary Count: <span class="badge bg-primary">
                  {
                    Object.keys(beneficiarySummary).length > 0 &&
                    parseFloat(beneficiarySummary[Object.keys(beneficiarySummary)[0]].count).toLocaleString()
                  }
                </span>
              </div>
              <div className="alert alert-success mt-4" role="alert">
                Your Total Donations: <span class="badge bg-primary">
                  {
                    Object.keys(donationSummary).length > 0 &&
                    parseFloat(donationSummary[Object.keys(donationSummary)[0]].totalDonations).toLocaleString()
                  }
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;