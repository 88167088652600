// UserContext.js
import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loginUser = (userData) => {
    setUserData(userData);
    setIsAuthenticated(true);
  };

  const logoutUser = () => {
    setUserData(null);
    setIsAuthenticated(false);
  };

  return (
    <UserContext.Provider value={{ userData, isAuthenticated, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

export default UserContext;
