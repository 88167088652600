import React, { useState } from "react";
import axios from "axios";
import Alert from "./Alert";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import { useUser } from '../context/UserContext';
import server from '../config/server';

const Login = () => {

  const { loginUser } = useUser();

  const initialState = {
    email: "",
    password: "",
  };

  const [loginData, setLoginData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const { email, password } = loginData;
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/signin`, { loginData });
      if (response.status === 200 && response.data.success) {
        loginUser(response.data.user);
        navigate("/");
      } else {
        setAlertMessage(`Could not sign in: ${response.data.error}`);
      }
    } catch (error) {
      setAlertMessage(`Could not sign in: ${error.message}`);
    }
    setIsLoading(false);
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100">
        <div className="col-md-6 bg-dark text-white d-flex align-items-center justify-content-center">
          <img
            src="/logos/logo-login.png"
            className="img-fluid mx-auto d-block"
            style={{
              width: "500px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            alt="Rearing Youth"
          />
        </div>
        <div className="col-md-6 bg-light d-flex align-items-center justify-content-center">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="card-body text-center">
              {/* <h3 className="card-title mb-3">
              Sign in
            </h3> */}
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form onSubmit={handleSubmit} className="mt-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text w-25" id="basic-addon1">Email</span>
                      <input
                        type="email"
                        className="form-control"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-group mt-4 mb-3">
                      <span className="input-group-text w-25" id="basic-addon1">Password</span>
                      <input
                        type="password"
                        className="form-control"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-outline-success mt-2">
                      <i className="bi bi-save me-2"></i>Sign in
                    </button>
                  </form>
                  {alertMessage && <Alert type="danger" message={alertMessage} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;