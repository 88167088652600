import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const AddProject = () => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    id: "",
    name: "",
    type: "",
    status: "",
    manager: "",
    startDate: "",
    endDate: "",
    notes: "",
  };

  const [projectData, setProjectData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);

  const { name, type, status, manager, startDate, endDate, notes } = projectData;

  const navigate = useNavigate();

  // State variables to manage the alert
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const generateProjectId = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getProjectCount`);
        const totalCount = response.data.projectCount;
        const newId = `P${String(totalCount + 1).padStart(5, '0')}`;
        setProjectData((prevData) => ({
          ...prevData,
          id: newId,
        }));
      } catch (error) {
        console.error("Error fetching total count of projects:", error);
      }
      setIsLoading(false);
    };
    generateProjectId();
  }, [formReset]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addProject`, {
        projectData
      });

      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setProjectData(initialState);
        setFormReset(!formReset);
      } else {
        setAlertType("danger");
        setAlertMessage(`Failed to add project: ${response.data.error}`);
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage(`Failed to add project: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    navigate(`/projects`);
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Add Project
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Type
                        </label>
                        <select
                          className="form-select"
                          id="type"
                          name="type"
                          value={type}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Project Type
                          </option>
                          <option key="RationDrive" value="Ration Drive">
                            Ration Drive
                          </option>
                          <option key="FinancialAssistance" value="Financial Assistance">
                            Financial Assistance
                          </option>
                          <option key="Other" value="Other">
                            Other
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          value={status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Project Status
                          </option>
                          <option key="NotStarted" value="Not Started">
                            Not Started
                          </option>
                          <option key="InProgress" value="In Progress">
                            In Progress
                          </option>
                          <option key="Completed" value="Completed">
                            Completed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="manager" className="form-label">
                          Manager
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="manager"
                          name="manager"
                          value={manager}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          value={startDate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="endDate" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          value={endDate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleBack}
                      >
                        <i className="bi bi-x-square me-2"></i>Back
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )
            }
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default AddProject;