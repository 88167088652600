import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Alert from "./Alert";
import Spinner from "./Spinner";
import Error from "./Error";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const Members = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const fetchMembers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getAllMembers`);
        if (response.data.success) {
          setMembers(response.data.members);
        } else {
          setAlertType("danger");
          setAlertMessage("Members could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Members could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchMembers();
  }, []);

  const handleEditMember = (member) => {
    let editMemberUrl = `/members/edit?id=${member.formId}`;
    navigate(editMemberUrl);
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : alertMessage ? (
              <Alert type={alertType} message={alertMessage} />
            ) : (
              <>
                <h2>Members</h2>
                <Link
                  to={`/membershipform`}
                  className="btn btn-outline-secondary mt-3 mb-4"
                >
                  Add Member
                </Link>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Occupation</th>
                      <th>Contact No.</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member) => (
                      <tr key={member.formId}>
                        <td>{member.id}</td>
                        <td>{member.name}</td>
                        <td>{member.role}</td>
                        <td>{member.occupation}</td>
                        <td>{member.contactNo}</td>
                        <td>{member.email}</td>
                        <td>
                          {member.status === "Active" ? (
                            <button className="btn btn-sm btn-success">
                              <i className="bi bi-check"></i> Active
                            </button>
                          ) : (
                            <button className="btn btn-sm btn-danger me-2">
                              <i className="bi bi-x"></i> Inactive
                            </button>
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-outline-success me-2"
                            onClick={() => handleEditMember(member)}
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                          {/* <RemoveTransaction
                      transactionType={transactionType}
                      transaction={transaction}
                      onRemove={handleRemovemember}
                    /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default Members;