import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Alert from "./Alert";
import Spinner from "./Spinner";
import Error from "./Error";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const Projects = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getAllProjects`);

        if (response.data.success) {
          if (response.data.projects.length)
            setProjects(response.data.projects);
          else {
            setAlertType("warning");
            setAlertMessage("No projects found.");
          }
        } else {
          setAlertType("error");
          setAlertMessage("Projects could not be retrieved.");
        }
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Projects could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchProjects();
  }, []);

  const handleEditProject = (project) => {
    let editProjectUrl = `/projects/edit?id=${project.formId}`;
    navigate(editProjectUrl);
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>Projects</h2>
                <Link
                  to={`/projects/add`}
                  className="btn btn-outline-secondary mt-3"
                >
                  Add Project
                </Link>
                {alertMessage ? (
                  <Alert type={alertType} message={alertMessage} />
                ) : (
                  <table className="table table-striped table-hover mt-4">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Manager</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <tr key={project.formId}>
                          <td>{project.id}</td>
                          <td>{project.name}</td>
                          <td>{project.type}</td>
                          <td>{project.manager}</td>
                          <td>
                            {project.status === "Not Started" ? (
                              <button className="btn btn-sm btn-secondary">
                                <i className="bi bi-circle"></i> Not Started
                              </button>
                            ) : project.status === "In Progress" ? (
                              <button className="btn btn-sm btn-primary">
                                <i className="bi bi-arrow-clockwise"></i> In Progress
                              </button>
                            ) : (
                              <button className="btn btn-sm btn-success">
                                <i className="bi bi-check"></i> Completed
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-success me-2"
                              onClick={() => handleEditProject(project)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            {/* <RemoveTransaction
                      transactionType={transactionType}
                      transaction={transaction}
                      onRemove={handleRemovemember}
                    /> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default Projects;