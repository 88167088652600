import React, { useEffect, useState } from "react";
import axios from "axios";
// import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";

const ReportTwo = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const fetchBeneficiaries = async () => {
      try {
        const response = await axios.post(`${server.apiUrl}/getAllBeneficiaries`, {
          userId: userData.id,
          role: userData.role
        });

        if (response.data.success) {
          setBeneficiaries(response.data.userBeneficiaries);
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiaries could not be retrieved.");
        }

        setIsLoading(false);
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Beneficiaries could not be retrieved.");
        setIsLoading(false);
      }
    };

    fetchBeneficiaries();
  }, [userData]);

  const handleBack = () => {
    navigate("/reports");
  };
  // const handleSaveAsPDF = () => {
  //   const doc = new jsPDF();
  //   doc.text("Beneficiaries Report", 10, 10);
  //   doc.autoTable({ html: '#beneficiariesTable' });
  //   doc.save("beneficiaries_report.pdf");
  // };

  return (
    <div>
      <div className="container">
        {isLoading ? (
          <Spinner />
        ) : alertMessage ? (
          <Alert type={alertType} message={alertMessage} />
        ) : (
          <>
            <div className="row border border-dark border-start-0 border-end-0 mt-3">
              <div className="col-md-6">
                <img
                  src="/logos/logo-login.png"
                  //className="img-fluid mx-auto d-block"
                  style={{
                    width: "250px"
                  }}
                  alt="Rearing Youth"
                />
              </div>
              <div className="col-md-6 text-end">
                <h2 className="mt-2">BENEFICIARY REPORT</h2>
                {/* <button className="btn btn-primary" onClick={handleSaveAsPDF}>Save as PDF</button> */}
                <button
                  type="button"
                  className="btn btn-outline-danger mt-2"
                  onClick={handleBack}
                >
                  <i className="bi bi-arrow-left me-2"></i>Back
                </button>
              </div>
            </div>
            <table id="beneficiariesTable" className="table table-striped table-hover mt-4">
              <thead>
                <tr>
                  <th className="text-center">Sr.</th>
                  <th>CNIC</th>
                  <th>Name</th>
                  <th className="text-center">Monthly Income (PKR)</th>
                  <th>Contact No.</th>
                  <th className="text-center">No. of Dependents</th>

                  {/* Columns:
                  Beneficiary Id,
                  Name,
                  contact no,
                  created by,
                  coordinator,
                  Notes (from project beneficiareis) and
                  Status (from Project beneficiaries) */}
                </tr>
              </thead>
              <tbody>
                {beneficiaries.map((beneficiary, index) => (
                  <tr key={beneficiary.formId}>
                    <td className="text-center">{index + 1}</td>
                    <td>{beneficiary.cnic}</td>
                    <td>{beneficiary.name}</td>
                    <td className="text-center">{parseFloat(beneficiary.monthlyIncome).toLocaleString()}</td>
                    <td>{beneficiary.contactNo}</td>
                    <td className="text-center">{beneficiary.dependentsCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportTwo;
