import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { useUser } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
//import ProjectBeneficiaries from './ProjectBeneficiaries';

const Donations = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [donations, setDonations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  // const [selectedBeneficiary, setSelectedBeneficiary] = useState(null); // State to store selected beneficiary
  // const [showModal, setShowModal] = useState(false); // State to manage modal visibility


  useEffect(() => {
    const fetchDonations = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${server.apiUrl}/getAllDonations`, {
          userId: userData.id,
          role: userData.role
        });

        if (response.data.success) {
          if (response.data.userDonations.length)
            setDonations(response.data.userDonations);
          else {
            setAlertType("warning");
            setAlertMessage("No donations found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Donations could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Donations could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchDonations();
  }, [userData]);

  const handleEditDonation = (donation) => {
    let editDonationUrl = `/donations/edit?id=${donation.formId}`;
    navigate(editDonationUrl);
  };

  // const handleModalOpen = (beneficiary) => {
  //   setSelectedBeneficiary(beneficiary);
  //   setShowModal(true);
  // };

  // const handleModalClose = () => {
  //   setShowModal(false);
  // };

  // const handleRemoveBeneficiary = (beneficiary) => {
  //   setBeneficiaries((prevBeneficiaries) =>
  //     prevBeneficiaries.filter((ben) => ben.formId !== beneficiary.formId)
  //   );
  // };

  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h2>Donations</h2>
            <Link
              to={`/donations/add`}
              className="btn btn-outline-secondary mt-3"
            >
              Add Donation
            </Link>
            {alertMessage ? (
              <Alert type={alertType} message={alertMessage} />
            ) : (
              <>
                <table className="table table-striped table-hover mt-4">
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>ID</th>
                      <th>Donor Name</th>
                      <th>Payment Date</th>
                      <th>Payment Method</th>
                      <th>Purpose</th>
                      <th className="text-center">Amount (PKR)</th>
                      <th>Status</th>
                      {userData && userData.role === "Admin" && (<th></th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {donations.map((donation) => (
                      <tr key={donation.formId}>
                        {/* <td>
                          <i className="bi bi-plus-square-fill bi-2x" style={{ "font-size": "20px" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"></i>
                        </td> */}
                        <td>{donation.id}</td>
                        <td>{donation.donorName}</td>
                        <td>{donation.paymentDate}</td>
                        <td>{donation.paymentMethod}</td>
                        <td>{donation.purpose}</td>
                        <td className="text-end">{parseFloat(donation.amount).toLocaleString()}</td>
                        <td>
                          {donation.isVerified === "true" ? (
                            <button className="btn btn-sm btn-success">
                              <i className="bi bi-check"></i> Verified
                            </button>
                          ) : (
                            <button className="btn btn-sm btn-danger me-2">
                              <i className="bi bi-x"></i> Not verified
                            </button>
                          )}
                        </td>
                        <td>
                          {donation.paymentReceipt && (
                            <button
                              className="btn btn-sm btn-outline-primary me-2"
                              onClick={() => window.open(donation.paymentReceipt, "_blank")}
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                          )}
                          {userData && userData.role === "Admin" && (
                            <button
                              className="btn btn-sm btn-outline-success me-2"
                              onClick={() => handleEditDonation(donation)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            /* <RemoveTransaction
                              transactionType={transactionType}
                              transaction={transaction}
                              onRemove={handleRemoveBeneficiary}
                            /> */
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <ProjectBeneficiaries />
                </div> */}
              </>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default Donations;